import React, { useMemo } from 'react';
import TableContainer from "../../Components/Common/TableContainerReactTable";
import { useNavigate } from 'react-router-dom';
import loadingIcon from '../../assets/images/loading.png'

export const ActiveActionList = ({ setOpenTab, actionsData }) => {
    const navigate = useNavigate();

    const columns = useMemo(
        () => [
            {
                header: "Action",
                accessorKey: "actionName",
                enableColumnFilter: false,
            },
            {
                header: "Task Name",
                accessorKey: "taskName",
                enableColumnFilter: false,
            },
            {
                header: "Workflow",
                accessorKey: "workflowName",
                enableColumnFilter: false,
                cell: (cell) => {
                    // Get the full row data using cell.row.original
                    const row = cell.row.original;
                    const key = row?.key;  // This is the URL you want to redirect to

                    return (
                        <span
                            className="text-blue-500 hover:underline cursor-pointer"
                            onClick={() => {
                                if (key) {
                                    navigate('/inbound-payment-processing', { state: { key: key } });
                                }
                            }}
                        >
                            {cell.getValue()} {/* Render the workflow name */}
                        </span>
                    );
                },
            },
            {
                header: "Created Date",
                accessorKey: "createdDate",
                enableColumnFilter: false,
            },
            {
                header: "Time Elapsed",
                accessorKey: "timeElapsed",
                enableColumnFilter: false,
                cell: (cell) => {
                    // Get the full row data using cell.row.original
                    const { timeElapsed } = cell.row.original;

                    return (timeElapsed ?
                        (<span
                        >
                            {cell.getValue()} {/* Render the workflow name */}
                        </span>) : (<div className="w-full h-full">
                            <img src={loadingIcon} className=" animate-spin h-4" />
                        </div>)
                    );
                },
            },
            {
                header: "Status",
                enableColumnFilter: false,
                accessorKey: "status",
                cell: (cell) => {
                    switch (cell.getValue().toLowerCase()) {
                        case "completed":
                            return (<span className="badge bg-success-subtle border-2 border-success text-success capitalize"> {cell.getValue()}</span>);
                        case "failed":
                            return (<span className="badge bg-danger-subtle border-2 border-danger text-danger capitalize"> {cell.getValue()}</span>);
                        case "ready":
                            return (<span className="badge bg-info-subtle border-2 border-info text-info capitalize"> {cell.getValue()}</span>);
                        case "scheduled":
                            return (<span className="badge bg-info-subtle border-2 border-info text-info capitalize"> {cell.getValue()}</span>);
                        default:
                            return (<span className="badge bg-warning-subtle border-2 border-warning text-warning capitalize"> {cell.getValue()}</span>);
                    }
                },
            },
            {
                header: "",
                accessorKey: " ",
                enableColumnFilter: false,
                // Conditionally render the cell only if the status is not "completed"
                cell: (cell) => {
                    const { status } = cell.row.original;
                    return <div className="d-flex justify-content-between">
                        {cell.getValue() || ''}
                        <i
                            className="ri-skip-forward-mini-line"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (status === "ready") {
                                    setOpenTab({
                                        id: cell.row.original.id,
                                        taskId: cell.row.original.taskId,
                                        taskName: cell.row.original.taskName,
                                        taskKeyName: cell.row.original.taskKeyName,
                                        actionPath: cell.row.original.actionPath,
                                        open: true,
                                        actionName: cell.row.original.actionName,
                                        workflowId: cell.row.original.workflowId
                                    });
                                }
                            }}
                        ></i>
                    </div>

                },
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <TableContainer
                columns={columns}
                data={actionsData}
                customPageSize={20}
                SearchPlaceholder="Search..."
                showPagination={false}
            // clickable
            />
        </React.Fragment>
    );
};
