import React, { useEffect } from "react";
import { Link } from "react-router-dom";
//import logo
import logo from "../assets/images/tfs-icon.png"

//Import Components
import VerticalLayout from "./VerticalLayouts";
import { Container } from "reactstrap";
import { FormatDateString, FormatTime, FormatDay } from "helpers/Formatters/Format";

const Sidebar = ({ layoutType }: any) => {

  useEffect(() => {
    const verticalOverlay = document.getElementsByClassName("vertical-overlay");
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function() {
        document.body.classList.remove("vertical-sidebar-enable");
      });
    }
  });

  const currentDate = new Date();
  const formattedDate = FormatDateString(currentDate);
  const formattedTime = FormatTime(currentDate.toString());
  const formattedDay = FormatDay(currentDate);

  return (
    <React.Fragment>
      <div className="app-menu navbar-menu">
        <div className="h-12 mx-4 my-3 flex justify-center">
          <Link to="/">
            <img src={logo} className="h-12 md:h-6 lg:h-12" alt="tfs-logo" />
          </Link>
        </div>
        <React.Fragment>
        <div id="scrollbar" className="flex h-5/6 sm:h-5/6 md:h-full lg:h-full flex-col justify-between">
        <Container fluid>
              <div id="two-column-menu"></div>
              <ul className="navbar-nav" id="navbar-nav">
                <VerticalLayout layoutType={layoutType} />
              </ul>
            </Container>
            <Container fluid>
              <hr className="mx-3 text-white" />
              <div className="flex gap-2 mx-3 content-center">
                <i className="rmx h-5 text-white bg-red text-xl ri-calendar-event-line"></i>
                <div className="flex gap-0 text-white flex-col">
                  <p className="leading-none mb-1">{formattedDate},</p>
                  <p>{formattedTime}</p>
                </div>
              </div>
              <div className="flex gap-2 mx-3">
                <i className="rmx h-5 text-white bg-red text-xl ri-calendar-2-line"></i>
                <p className="text-white pt-1.5">{formattedDay}</p></div>
            </Container>
          </div>
          <div className="sidebar-background"></div>
        </React.Fragment>
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
};

export default Sidebar;
