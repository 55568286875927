import React, { useMemo } from 'react';
import TableContainer from "../../Components/Common/TableContainerReactTable";
import { useNavigate } from 'react-router-dom';
import { GET_WORKFLOWS } from 'slices/constants/constants';
import { determineBaseUrlAndPerformApiCall } from 'slices/interceptor/interceptor';
import { FormatDate, FormatTime, FormatName, FormatStatus, GetInitials } from "../../helpers/Formatters/Format";

export const TicketListing = ({ tickets }) => {
    const navigate = useNavigate();

    const columns = useMemo(
        () => [
            {
                header: "Ticket ID",
                accessorKey: "ticketID",
                enableColumnFilter: false,
                cell: (cell) => {
                    // Get the full row data using cell.row.original
                    const row = cell.row.original;
                    const ticketUrl = row?.url;  // This is the URL you want to redirect to

                    return (
                        <span
                            className="text-blue-500 hover:underline cursor-pointer"
                            onClick={() => {
                                if (ticketUrl) {
                                    window.open(ticketUrl, '_blank'); // Open in new tab
                                } else {
                                    console.error('URL not found for this ticket');
                                }
                            }}
                        >
                            {cell.getValue()} {/* Render the ticket ID */}
                        </span>
                    );
                },
            }, 
            {
                header: "Workflow Name",
                accessorKey: "workflowName",
                enableColumnFilter: false,
                cell: (cell) => {
                    // Get the full row data using cell.row.original
                    const { workflowID } = cell.row.original;

                    return (
                        <span
                            className="text-blue-500 hover:underline cursor-pointer"
                            onClick={() => {
                                if (workflowID) {
                                    determineBaseUrlAndPerformApiCall(`${GET_WORKFLOWS}/${workflowID}`, 'GET')
                                    .then((response) => {
                                        const dataToPass = {
                                            workflow_id: workflowID,
                                            name: response?.workflow_display_name === "" ? "" : FormatName(response?.workflow_display_name),  // Convert workflow_name to title case
                                            date: response?.created_at !== "" ? FormatDate(response?.created_at) : "",   // Format start_time to MM-DD-YYYY
                                            start_time: response?.start_time !== "" ? FormatTime(response?.start_time) : "",  // Format time to AM/PM
                                            end_time: response?.end_time !== "" ? FormatTime(response?.end_time) : "",  // Format time to AM/PM
                                            processor: GetInitials(response?.processor_name),  // Get initials from processor_name
                                            current_Task: response?.current_running_task || '',  // Handle missing current_running_task
                                            status: response?.status !== "" ? FormatStatus(response?.status, response?.current_running_task) : "",
                                            tasks: response?.tasks,            // Format status
                                            allocated_time: response?.allocated_time,
                                            started_time: response?.start_time,
                                        }

                                        if (dataToPass){
                                            navigate('/inbound-payment-processing', { state: { key: dataToPass } });
                                        }
                                    })
                                }
                            }}
                        >
                            {cell.getValue()} {/* Render the workflow name */}
                        </span>
                    );
                },
            },
            {
                header: "Task Name",
                accessorKey: "taskName",
                enableColumnFilter: false,
            },
            {
                header: "Subject",
                accessorKey: "subject",
                enableColumnFilter: false,
            },
            {
                header: "Created Date",
                accessorKey: "createAt",
                enableColumnFilter: false,
            },
             {
                header: "Priority",
                accessorKey: "priority",
                enableColumnFilter: false,
                cell: (cell) => {
                    switch (cell?.getValue()?.toLowerCase()) {
                        case "urgent":
                            return (<span className="text-danger font-semibold capitalize"> {cell.getValue()}</span>);
                        default:
                            return (<span className="text-info font-semibold capitalize"> {cell.getValue()}</span>);
                    }
                },
            },
            {
                header: "Status",
                enableColumnFilter: false,
                accessorKey: "status",
                cell: (cell) => {
                    switch (cell?.getValue()?.toLowerCase()) {
                        case "solved":
                            return (<span className="badge bg-success-subtle border-2 border-success text-success capitalize"> {cell.getValue()}</span>);
                        case "open":
                            return (<span className="badge bg-info-subtle border-2 border-info text-info capitalize"> {cell.getValue()}</span>);
                        default:
                            return (<span className="badge bg-danger-subtle border-2 border-danger text-danger capitalize"> {cell.getValue()}</span>);
                    }
                },
            },
        ],
        []
    );

    return (
        <React.Fragment >
            <TableContainer
                columns={(columns || [])}
                data={(tickets || [])}
                customPageSize={7}
                SearchPlaceholder='Search Products...'
                showPagination={true}
            />
        </React.Fragment >
    );
};