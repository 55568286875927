import React, { useMemo } from 'react';
import TableContainer from "../../Components/Common/TableContainerReactTable";

export const WorkflowHistory = ({ workflowData }: any) => {
    const columns = useMemo(
        () => [
            {
                header: "Name",
                accessorKey: "name",
                enableColumnFilter: false,
            },
            {
                header: "Date",
                accessorKey: "date",
                enableColumnFilter: false,
            },
            {
                header: "Start Time",
                accessorKey: "start_time",
                enableColumnFilter: false,
            },
            {
                header: "End Time",
                accessorKey: "end_time",
                enableColumnFilter: false,
            },
            {
                header: "Processor",
                accessorKey: "processor",
                enableColumnFilter: false,
            },
            {
                header: "Status",
                enableColumnFilter: false,
                accessorKey: "status",
                cell: (cell: any) => {
                    switch (cell.getValue().toLowerCase()) {
                        case "completed":
                            return (<span className="badge bg-success-subtle border-2 border-success text-success capitalize"> {cell.getValue()}</span>);
                        case "failed":
                            return (<span className="badge bg-danger-subtle border-2 border-danger text-danger capitalize"> {cell.getValue()}</span>);
                        case "scheduled":
                            return (<span className="badge bg-info-subtle border-2 border-info text-info capitalize"> {cell.getValue()}</span>);
                        default:
                            return (<span className="badge bg-warning-subtle border-2 border-warning text-warning capitalize"> {cell.getValue()}</span>);
                    }
                },
            },
        ],
        []
    );

    return (
        <React.Fragment >
            <TableContainer
                columns={(columns || [])}
                data={(workflowData || [])}
                customPageSize={5}
                SearchPlaceholder='Search Products...'
                showPagination
                clickable={true}
                navigateTo='/inbound-payment-processing'
            />
        </React.Fragment >
    );
};