import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react'

type NetworkStatusContextType = {
  isOffline: boolean
}

const NetworkStatusContext = createContext<NetworkStatusContextType | undefined>(undefined)

export const NetworkStatusProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isOffline, setIsOffline] = useState(!navigator.onLine)

  useEffect(() => {
    const updateNetworkStatus = () => {
      setIsOffline(!navigator.onLine)
      console.log("Network status changed:", navigator.onLine ? "Online" : "Offline") // Log status
    }

    window.addEventListener('online', updateNetworkStatus)
    window.addEventListener('offline', updateNetworkStatus)

    // Cleanup listeners on component unmount
    return () => {
      window.removeEventListener('online', updateNetworkStatus)
      window.removeEventListener('offline', updateNetworkStatus)
    }
  }, [])

  return (
    <NetworkStatusContext.Provider value={{ isOffline }}>
      {children}
    </NetworkStatusContext.Provider>
  )
}

export const useNetworkStatus = () => {
  const context = useContext(NetworkStatusContext)
  if (!context) throw new Error("useNetworkStatus must be used within a NetworkStatusProvider")
  return context
}
