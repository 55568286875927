import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
// import reusable components
import { Card, CardBody, Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane, CardHeader } from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import classnames from 'classnames'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
// import page components
import { CompletedActionList } from './CompletedActionList'
import { GetStatiscs } from 'pages/Workflow/InboundPaymentProcessing/Statistics'
// Redux for workflows
import { fetchActions } from 'slices/Actions/thunk'
// import utilities
import { FormatName, FormatDate, FormatTime, GetInitials, FormatStatus, CalculateElapsedTime } from 'helpers/Formatters/Format'
import { fetchWorkflows } from 'slices/workflow/thunk'
import { ActiveActionList } from './ActiveActionsList';
import { getStatisticsByWorkflowId } from 'slices/workflow/statistics/thunk';
import { determineBaseUrlAndPerformApiCall } from 'slices/interceptor/interceptor';

const Action = () => {
    document.title = "Actions | TFS Processing App"

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const { actions, loadingActions } = useSelector((state) => state.Action)
    const { workflows } = useSelector((state) => state.Workflow)
    const { statistics, statisticsLoading } = useSelector((state) => state.Statistics)

    const ticketTitle = useRef(null)
    const ticketSubject = useRef(null)

    const [activeTab, setActiveTab] = useState('1')
    const [openTab, setOpenTab] = useState({ open: false, disabled: false });
    const [openEscalation, setOpenEscalation] = useState({ open: false, disabled: false })

    const [stats, setStats] = useState([])
    const [pendingActions, setPendingActions] = useState([])
    const [completedActions, setCompletedActions] = useState([])
    const [failedActions, setFailedActions] = useState([])

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    useEffect(() => {
       
    }, [])

    const getWorkflowById = (workflows, targetId) => workflows?.find((workflow) => workflow?.id === targetId)

    useEffect(() => {
        // We call get actions on page load
        dispatch(fetchActions())
         // We call get workflows on page load
         dispatch(fetchWorkflows())
    }, [])

    useEffect(() => {
        if (openTab?.open) {
            // Get Task ID
            if (openTab.taskKeyName !== "create_bundle") {
                // Workflow
                const targetId = openTab?.workflowId
                const matchingWorkflow = getWorkflowById(workflows, targetId)
                dispatch(getStatisticsByWorkflowId(targetId, matchingWorkflow))
            }
        }
    }, [openTab])

    useEffect(() => {
        if (statistics && !statisticsLoading) {
            setStats(statistics)
        }
    }, [statistics, statisticsLoading])

    useEffect(() => {
        // Whenever workflow data is changes, update the display data
        if (actions && !loadingActions) {
            const { completed, pending, failed } = separateActionsByStatus(actions)

            setCompletedActions(completed)
            setPendingActions(pending)
            setFailedActions(failed)

            const timerId = setInterval(() => {
                if (pending) {
                    // Calculate time elapsed for the pending time
                    // Workflow's Startime and Current time subtraction
                    const updatedPending = pending?.map((action) => {
                        // Calculate and return a new object with the updated timeElapsed
                        return {
                            ...action,  // Copy all other properties of the action
                            timeElapsed: CalculateElapsedTime(action?.createdAt)  // Add the updated timeElapsed
                        };
                    })

                    setPendingActions(updatedPending)
                }
            }, 1000)

            return () => clearInterval(timerId);
        }


    }, [actions, loadingActions])


    // TODO: Remove from here
    const separateActionsByStatus = (response) => {
        const completed = []
        const pending = []
        const failed = []

        response?.forEach(item => {
            if (workflows) {
                const matchingWorkflow = workflows?.find((workflow) => workflow?.id === item?.workflow_id)

                const formattedWorkflow = {
                    id: item?.id,
                    actionName: item?.display_name,  // Convert workflow_name to title case
                    taskName: FormatName(item?.task_name),
                    taskId: item?.task_id,
                    workflowId: item?.workflow_id,
                    workflowName: FormatName(item?.workflow_name),
                    createdDate: item?.created_at !== "" ? FormatDate(item?.created_at) + " ," + FormatTime(item?.created_at) : "",
                    completedDate: item?.end_time !== "" ? FormatDate(item?.end_time) + " ," + FormatTime(item?.end_time) : "",
                    timeElapsed: "",
                    actionPath: item?.action_path,
                    status: item?.status,
                    taskKeyName: item?.task_key_name,
                    createdAt: item?.created_at,
                    key: {
                        workflow_id: item?.workflow_id,
                        name: FormatName(item?.workflow_name),  // Convert workflow_name to title case
                        date: matchingWorkflow?.created_at !== "" ? FormatDate(matchingWorkflow?.created_at) : "",   // Format start_time to MM-DD-YYYY
                        start_time: matchingWorkflow?.start_time !== "" ? FormatTime(matchingWorkflow?.start_time) : "",  // Format time to AM/PM
                        end_time: matchingWorkflow?.end_time !== "" ? FormatTime(matchingWorkflow?.end_time) : "",  // Format time to AM/PM
                        processor: GetInitials(matchingWorkflow?.processor_name),  // Get initials from processor_name
                        current_Task: matchingWorkflow?.current_running_task || '',  // Handle missing current_running_task
                        status: matchingWorkflow?.status !== "" ? FormatStatus(matchingWorkflow?.status, matchingWorkflow?.current_running_task) : "",
                        tasks: matchingWorkflow?.tasks,            // Format status
                        allocated_time: matchingWorkflow?.allocated_time,
                        started_time: matchingWorkflow?.start_time,
                    }
                }

                if (item?.status === 'completed') {
                    completed.push(formattedWorkflow)
                } else if (item?.status === 'ready') {
                    pending.push(formattedWorkflow)
                } else if (item?.status === 'failed') {
                    failed.push(formattedWorkflow)
                }
            }
        })

        return { completed, pending, failed }
    }

    const handleStartButtonClick = async (taskId, actionPath, taskKeyName, workflowId) => {
        // Save the disabled state to localStorage
        sessionStorage.setItem(`started_${taskId}`, JSON.stringify(true));

        setOpenTab({ disabled: true, open: false });

        const body = JSON.stringify({
            [taskKeyName === "create_bundle" ? "create_bundle_task_id"
                : taskKeyName === "approve_create_bundle" ? "approve_create_bundle_task_id"
                    : taskKeyName === "upload_payment_bundle" ? "upload_bundle_task_id" : "approve_upload_bundle_task_id"]: taskId
        })

        await determineBaseUrlAndPerformApiCall(`${actionPath}`, 'POST', body).then((response) => {
            if (!response.error) {
                if (workflows) {
                    const item = workflows?.find((workflow) => workflow?.id === workflowId)

                    if (item) {
                        const dataToPass = {
                            key: {
                                workflow_id: workflowId,
                                name: FormatName(item?.workflow_display_name),  // Convert workflow_name to title case
                                date: item?.created_at !== "" ? FormatDate(item?.created_at) : "",   // Format start_time to MM-DD-YYYY
                                start_time: item?.start_time !== "" ? FormatTime(item?.start_time) : "",  // Format time to AM/PM
                                end_time: item?.end_time !== "" ? FormatTime(item?.end_time) : "",  // Format time to AM/PM
                                processor: GetInitials(item?.processor_name),  // Get initials from processor_name
                                current_Task: item?.current_running_task || '',  // Handle missing current_running_task
                                status: item?.status !== "" ? FormatStatus(item?.status, item?.current_running_task) : "",
                                tasks: item?.tasks,            // Format status
                                allocated_time: item?.allocated_time,
                                started_time: item?.start_time,
                            }
                        };
                        if (dataToPass) {
                            // Navigate to the target component and pass data using state
                            navigate('/inbound-payment-processing', { state: dataToPass });
                        }
                    }
                }
                switch (taskKeyName) {
                    case "create_bundle":
                        toast.success("Workflow Started Sucessfully !", {
                            position: "top-center"
                        })
                        break
                    case "approve_create_bundle":
                        toast.success("Bundle Approved Sucessfully !", {
                            position: "top-center"
                        })
                        break
                    case "upload_payment_bundle":
                        toast.success("Upload Payment Bundle Started Successfully!", {
                            position: "top-center"
                        })
                        break
                    case "upload_bundle":
                        toast.success("Payment Bundle Approved Sucessfully !", {
                            position: "top-center"
                        })
                        break
                }
            }
            if (response.error) {
                toast.error(response.error.message, {
                    position: "top-center"
                })
            }
        }).catch((errorResp) => {
            toast.error(errorResp, {
                position: "top-center"
            })
        })

    };

    const handleTicketEsclations = async (taskId, taskKeyName, workflowId) => {
        if (ticketTitle?.current?.value === "") {
            toast.error("Title of the Ticket missing!", {
                position: "top-center"
            })
        }

        if (ticketSubject?.current?.value === "") {
            toast.error("Subject of the Ticket missing !", {
                position: "top-center"
            })
        }

        setOpenTab({ open: false });
        setOpenEscalation({ open: false })

        sessionStorage.setItem(`escalated_${taskId}`, JSON.stringify(true))

        if (workflows) {
            // Loop the workflow and find matching workflow id
            const matchingWorkflows = getWorkflowById(workflows, workflowId)

            const taskNameToLookFor = taskKeyName === "approve_create_bundle" ? "create_bundle" : "upload_payment_bundle"

            const task = matchingWorkflows?.tasks?.find((task) => task?.key_name === taskNameToLookFor)

            const matchingTask = matchingWorkflows?.tasks?.find((task) => task?.key_name === taskKeyName)

            const taskIdKey =
                taskKeyName === "approve_create_bundle" ? "create_bundle_task_id" :
                    taskKeyName === "upload_bundle" ? "upload_bundle_task_id" : ""

            const data = {
                [ taskIdKey ]: task?.id, // Ensure valid key
                ticket_title: ticketTitle?.current?.value,
                ticket_subject: ticketSubject?.current?.value,
            };

            await determineBaseUrlAndPerformApiCall(`${matchingTask?.escalation_path}`, 'POST', data).then((response) => {
                if (!response.error) {
                    toast.success("Ticket raised successfully !", {
                        position: "top-center"
                    })
                    // Reset the input fields
                    ticketTitle.current.value = ""
                    ticketSubject.current.value = ""
                }
                if (response.error) {
                    toast.error(response.error.message, {
                        position: "top-center"
                    })
                }
            }).catch((errorResp) => {
                toast.error(errorResp, {
                    position: "top-center"
                })
            })

        }
    }

    return (
        <React.Fragment>
            <ToastContainer />
            {loadingActions ? (
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            ) : (<div className="page-content relative" id="processingContent">
                <div
                    className={`slider-content w-11/12 sm:w-3/6 md:5/12 lg:w-2/6 h-full transition-all duration-700 ${openEscalation.open ? 'translate-x-0 opacity-100' : ' opacity-0 translate-x-full'
                        }`}
                >
                    <div className='h-[75%] pl-3'>
                        <div className='bg-white border-2 border-[#B6B6B6] py-2 h-full rounded-lg  mb-10'>
                            <div className='flex gap-3 items-center px-4 py-2'>
                                <div onClick={() => setOpenEscalation({ open: false })} className='cursor-pointer'>
                                    <i className="ri-close-line font-extralight bg-red-100 p-2 rounded-md text-red-500"></i>
                                </div>
                                <p className='uppercase font-medium text-sm m-0'>{openEscalation.actionName}</p>
                            </div>
                            <hr className='mt-2 text-gray-500' />
                            <div className='px-3 flex flex-col gap-2 bg-stone-100 rounded-xl mx-3 py-3'>
                                <h6 className='uppercase font-semibold'>Create Ticket</h6>
                                <input type="text" className="border-0 rounded px-3 py-2" placeholder="Title" ref={ticketTitle} />
                                <textarea className="border-0 px-3 py-2" rows={5} placeholder="Subject" ref={ticketSubject}></textarea>
                                {/* <input type=""/> */}
                                <div className='flex justify-end gap-2 mt-2'>
                                    <button
                                        disabled={openTab.disabled}
                                        className='bg-white shadow-gray-300 shadow-inner-all-sides text-xs font-bold py-1 px-3 rounded-md border-2 border-[#DBDBDB] transition-all duration-200 active:bg-gray-400 active:shadow-none'
                                        onClick={() => setOpenEscalation({ open: false })}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        disabled={openTab.disabled}
                                        className='bg-[#31506A] shadow-inner-all-sides text-xs text-white font-bold py-1 px-3 rounded-md border-[0.09px] border-[#51718b] transition-all duration-200 active:bg-[#31506A] active:shadow-none'
                                        onClick={() => {
                                            handleTicketEsclations(openEscalation?.id, openEscalation?.taskKeyName, openEscalation?.workflowId)
                                        }}
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div
                    className={`slider-content w-11/12 sm:w-3/6 md:5/12 lg:w-2/6 h-full transition-all duration-700 ${openTab.open ? 'translate-x-0 opacity-100' : ' opacity-0 translate-x-full'
                        }`}
                >
                    <div className='h-[75%] pl-3'>
                        <div className='bg-white border-2 border-[#B6B6B6] py-2 h-full rounded-lg mb-10'>
                            <div className='flex gap-3 items-center px-4 py-1'>
                                <div onClick={() => setOpenTab({ open: false })} className='cursor-pointer'>
                                    <i className="ri-close-line font-extralight bg-red-100 p-2 rounded-md text-red-500"></i>
                                </div>
                                <p className='uppercase font-medium text-sm m-0'>{openTab.actionName}</p>
                            </div>
                            <hr className='mt-2 text-gray-500' />
                            <div className='px-2 flex flex-col h-5/6 gap-4'>
                                <div className='flex-col gap-4'>
                                    <div className='flex-col gap-2 px-3'>
                                        <div className='flex w-3/4 pb-2'>
                                            <span className='font-semibold  w-1/2'>Task Name: </span>
                                            <span>{openTab?.taskName}</span>
                                        </div>
                                        <div className='flex w-3/4 pb-2'>
                                            <span className='font-semibold w-1/2'>Task ID: </span>
                                            <span>{openTab?.id}</span>
                                        </div>
                                        <div className='flex w-3/4'>
                                            <span className='font-semibold w-1/2'>Workflow ID: </span>
                                            <span>{openTab?.workflowId}</span>
                                        </div>
                                    </div>
                                </div>
                                {(openTab?.taskKeyName !== "create_bundle") && (
                                    <Card className='border-1 mt-4'>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">Statistics</h5>
                                        </CardHeader>
                                        <CardBody className='text-xs'>
                                            <GetStatiscs statistics={stats} />
                                        </CardBody>
                                    </Card>)
                                }
                            </div>

                            <div className='flex justify-end gap-2 mr-4'>
                                {openTab.taskKeyName === "approve_create_bundle" || openTab.taskKeyName === "upload_bundle" ?
                                    <button
                                        disabled={openTab.disabled}
                                        className='bg-white shadow-gray-300 shadow-inner-all-sides text-xs font-bold py-1 px-3 rounded-md border-2 border-[#DBDBDB] transition-all duration-200 active:bg-gray-400 active:shadow-none'
                                        onClick={() => {
                                            setOpenTab({ open: false })
                                            setOpenEscalation({
                                                open: true,
                                                id: openTab.id,
                                                url: openTab.escalation_url,
                                                taskKeyName: openTab?.taskKeyName,
                                                actionName: openTab?.actionName,
                                                workflowId: openTab?.workflowId
                                            })
                                        }}                                    >
                                        Escalate
                                    </button> :
                                    <button
                                        disabled={openTab.disabled}
                                        className='bg-white shadow-gray-300 shadow-inner-all-sides text-xs font-bold py-1 px-3 rounded-md border-2 border-[#DBDBDB] transition-all duration-200 active:bg-gray-400 active:shadow-none'
                                        onClick={() => setOpenTab({ open: false })}
                                    >
                                        Cancel
                                    </button>}
                                <button
                                    disabled={openTab.disabled}
                                    className='bg-[#31506A] shadow-inner-all-sides text-xs text-white font-bold py-1 px-3 rounded-md border-[0.09px] border-[#51718b] transition-all duration-200 active:bg-[#31506A] active:shadow-none'
                                    onClick={() => handleStartButtonClick(openTab.taskId, openTab.actionPath, openTab.taskKeyName, openTab.workflowId)}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
                <Container fluid>
                    <BreadCrumb title="Action List" pageTitle="Action summary" />
                    <Row className='relative'>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Nav tabs className="nav-tabs-custom nav-success mb-3">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '1' })}
                                                onClick={() => toggle('1')}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                PENDING
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '2' })}
                                                onClick={() => toggle('2')}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                COMPLETED
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '3' })}
                                                onClick={() => toggle('3')}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                FAILED
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <ActiveActionList actionsData={pendingActions} setOpenTab={setOpenTab} />
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <CompletedActionList actionsData={completedActions} />
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <CompletedActionList actionsData={failedActions} />
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>)}
        </React.Fragment>
    )
}

export default Action