import { FormatDateString } from 'helpers/Formatters/Format';
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

interface BreadCrumbProps {
    title: string,
    pageTitle: string,
    action: string,
    startTime?: any,
}

const BreadCrumb = ({ title, pageTitle, action, startTime }: BreadCrumbProps) => {
    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <div className='flex gap-2 items-center h-full'>
                            <h4 className="mb-0">{title}</h4>
                            <h5 className='mb-0 text-gray-500'> {startTime && startTime !== "" ? `| ${FormatDateString(startTime)}` : ''}</h5>
                        </div>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to={action}>{pageTitle}</Link></li>
                                <li className="breadcrumb-item active disabled">{pageTitle !== title && title}</li>
                            </ol>
                        </div>

                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default BreadCrumb;