import React, { useEffect } from 'react';
import { useNetworkStatus } from './NetworkStatusContext';
import { Button, Modal, ModalBody } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';

const NetworkStatusPopup: React.FC = () => {
    const { isOffline } = useNetworkStatus();

    const handleUserRefresh = () => {
        window.location.reload();
    }

    useEffect(() => {
        // Retrieve the previous offline status from localStorage
        const wasOffline = localStorage.getItem("wasOffline") === "true"

        if (wasOffline && !isOffline) {
            // Show the toast when coming back online
            toast.success("You're back online!",
                { position: "top-center" }
            );
        }

        // Update localStorage when going offline
        if (isOffline) {
            localStorage.setItem("wasOffline", "true");
        } else {
            localStorage.setItem("wasOffline", "false");
        }
    }, [isOffline])

    return (
        <>
            <Modal centered fullscreen={true} isOpen={isOffline} >
                <ModalBody className="d-flex justify-content-center align-items-center vh-100 text-center flex-column gap-4">
                    <i className='rmx ri-wifi-off-line text-6xl' />
                    <div>
                        <h3 className='font-semibold mb-2'>Uh-oh, no connection! :(</h3>
                        <p>It looks like you've lost connection. Please check your network and try again when you're back online.</p>
                    </div>
                    <Button onClick={handleUserRefresh}>Refresh Page</Button>
                </ModalBody>
            </Modal>
            <ToastContainer />
        </>
    );
};

export default NetworkStatusPopup;
