import React, { useMemo } from 'react';
import TableContainer from "../../Components/Common/TableContainerReactTable";
import { useNavigate } from 'react-router-dom';

// type ActionData = {
//     Action: string;
//     "Task Name": string;
//     Type: string;
//     Time: string;
//     "Time Elapsed": string;
//     task_uuid: string;
// };

// type Props = {
//     setOpenTab: any;
//     actionsData: any;
//     setActionsData :any;
//     setActionsName: any;
//     setActionTaskId: any;
//     setActionUrl:any;
// };

export const CompletedActionList = ({ actionsData }) => {
    const navigate = useNavigate();

    const columns = useMemo(
        () => [
            {
                header: "Action",
                accessorKey: "actionName",
                enableColumnFilter: false,
            },
            {
                header: "Task Name",
                accessorKey: "taskName",
                enableColumnFilter: false,
            },
            {
                header: "Workflow",
                accessorKey: "workflowName",
                enableColumnFilter: false,
                cell: (cell) => {
                    // Get the full row data using cell.row.original
                    const row = cell.row.original;
                    const key = row?.key;  // This is the URL you want to redirect to

                    return (
                        <span
                            className="text-blue-500 hover:underline cursor-pointer"
                            onClick={() => {
                                if (key) {
                                    navigate('/inbound-payment-processing', { state: { key: key } });
                                }
                            }}
                        >
                            {cell.getValue()} {/* Render the workflow name */}
                        </span>
                    );
                },
            },
            {
                header: "Created Date",
                accessorKey: "createdDate",
                enableColumnFilter: false,
            },
            {
                header: "Completed Date",
                accessorKey: "completedDate",
                enableColumnFilter: false
            },
            {
                header: "Status",
                enableColumnFilter: false,
                accessorKey: "status",
                cell: (cell) => {
                    switch (cell.getValue().toLowerCase()) {
                        case "completed":
                            return (<span className="badge bg-success-subtle border-2 border-success text-success capitalize"> {cell.getValue()}</span>);
                        case "failed":
                            return (<span className="badge bg-danger-subtle border-2 border-danger text-danger capitalize"> {cell.getValue()}</span>);
                        case "ready":
                            return (<span className="badge bg-info-subtle border-2 border-info text-info capitalize"> {cell.getValue()}</span>);
                        case "scheduled":
                            return (<span className="badge bg-info-subtle border-2 border-info text-info capitalize"> {cell.getValue()}</span>);
                        default:
                            return (<span className="badge bg-warning-subtle border-2 border-warning text-warning capitalize"> {cell.getValue()}</span>);
                    }
                },
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <TableContainer
                columns={columns}
                data={actionsData}
                customPageSize={20}
                SearchPlaceholder="Search..."
                showPagination={false}
            // clickable
            />
        </React.Fragment>
    );
};
