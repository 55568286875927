import React, { Fragment, useEffect, useState } from "react";
import { CardBody, Col, Row, Table } from "reactstrap";
import { useNavigate } from "react-router-dom";

import {
  Column,
  Table as ReactTable,
  ColumnFiltersState,
  FilterFn,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender
} from '@tanstack/react-table';

import { rankItem } from '@tanstack/match-sorter-utils';

// Column Filter
const Filter = ({
  column
}: {
  column: Column<any, unknown>;
  table: ReactTable<any>;
}) => {
  const columnFilterValue = column.getFilterValue();

  return (
    <>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? '') as string}
        onChange={value => column.setFilterValue(value)}
        placeholder="Search..."
        className="w-36 border shadow rounded"
        list={column.id + 'list'}
      />
      <div className="h-1" />
    </>
  );
};

// Global Filter
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <input {...props} value={value} id="search-bar-0" className="form-control border-0 search" onChange={e => setValue(e.target.value)} />
  );
};

interface TableContainerProps {
  columns?: any;
  data?: any;
  isGlobalFilter?: any;
  handleTaskClick?: any;
  customPageSize?: any;
  tableClass?: any;
  theadClass?: any;
  trClass?: any;
  iscustomPageSize?: any;
  thClass?: any;
  divClass?: any;
  SearchPlaceholder?: any;
  handleLeadClick?: any;
  handleCompanyClick?: any;
  handleContactClick?: any;
  handleTicketClick?: any;
  isBordered?: any;
  showPagination?: any;
  clickable?: boolean;
  navigateTo?: any;
  isHistoryNavigate?: boolean;
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  customPageSize,
  theadClass,
  trClass,
  thClass,
  divClass,
  SearchPlaceholder,
  showPagination,
  clickable,
  navigateTo,
  isHistoryNavigate = true

}: TableContainerProps) => {
  const history = useNavigate()
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank
    });
    return itemRank.passed;
  };

  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel()
  });

  const {
    getHeaderGroups,
    getRowModel,
    getCanPreviousPage,
    getCanNextPage,
    getPageOptions,
    setPageIndex,
    nextPage,
    previousPage,
    setPageSize,
    getState
  } = table;

  useEffect(() => {
    if (Number(customPageSize)){
      setPageSize(Number(customPageSize));
    }
  }, [customPageSize, setPageSize]);

  return (
    <Fragment>
      {isGlobalFilter && <Row className="mb-3">
        <CardBody className="border border-dashed border-end-0 border-start-0">
          <form>
            <Row>
              <Col sm={5}>
                <div className="search-box me-2 mb-2 d-inline-block col-12">
                  <DebouncedInput
                    value={globalFilter ?? ''}
                    onChange={value => setGlobalFilter(String(value))}
                    placeholder={SearchPlaceholder}
                  />
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Row>}

      <div className="table-responsive">
        <div className={divClass}>
          <Table hover className={'table table-striped table-nowrap align-middle mb-0'}>
            <thead className={theadClass}>
              {getHeaderGroups().map((headerGroup: any) => (
                <tr className={trClass} key={headerGroup.id}>
                  {headerGroup.headers.map((header: any) => (
                    <th key={header.id} className={thClass}
                      {...(clickable === true && { onClick: header.column.getToggleSortingHandler() })}
                    >
                      {header.isPlaceholder ? null : (
                        <React.Fragment>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' ',
                            desc: ' ',
                          }[header.column.getIsSorted() as string] ?? null}
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </React.Fragment>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody>
              {data.length !== 0 ? (
                getRowModel().rows.map((row: any) => (
                  <tr
                    key={row.id}
                    className={`${clickable ? "transition-shadow hover:shadow-md hover:shadow-neutral-400" : ""
                      }`}
                  >
                    {row.getVisibleCells().map((cell: any) => (
                      <td
                        key={cell.id}
                        {...(clickable && {
                          onClick: () => {
                            if (isHistoryNavigate) {
                              const additionalData = { key: data[row.id] };
                              history(navigateTo, { state: additionalData });
                            }
                          },
                        })}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        {/* {clickable &&
                    <td onClick={() => {
                      const additionalData = { key: row.getVisibleCells() }
                      history(navigateTo, { state: additionalData })
                    }}>
                      <i className='rmx ri-skip-forward-mini-line'></i>
                    </td>} */}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                // Wrap the fallback message inside a <tr> element
                <tr>
                  <td className="text-center py-4 text-gray-600" colSpan={7}>
                    You are all caught up!
                    {/* <img src={noDataFound} alt="No data found" className="mx-auto" /> */}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>

      {
        showPagination && data.length > 5 && (<Row className="align-items-center mt-2 g-3 text-center text-sm-start">
          <div className="col-sm">
            {/* <div className="text-muted">Showing<span className="fw-semibold ms-1">{getState().pagination.pageSize}</span> of <span className="fw-semibold">{data.length}</span> Results */}
            <div className="text-muted">Total Of<span className="fw-semibold ms-1">{data.length}</span> Results
            </div>
          </div>
          <div className="col-sm-auto">
            <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
              <li className={!getCanPreviousPage() ? "page-item disabled" : "page-item"}>
                <button className="page-link" onClick={previousPage}>Previous</button>
              </li>
              {getPageOptions().map((item: any, key: number) => (
                <li className="page-item" key={key}>
                  <button
                    className={getState().pagination.pageIndex === item ? "page-link active" : "page-link"}
                    onClick={() => setPageIndex(item)}
                  >
                    {item + 1}
                  </button>
                </li>
              ))}
              <li className={!getCanNextPage() ? "page-item disabled" : "page-item"}>
                <button className="page-link" onClick={nextPage}>Next</button>
              </li>
            </ul>

          </div>
        </Row>)
      }
    </Fragment >
  );
};

export default TableContainer;