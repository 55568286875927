import axios from "axios";
import { SessionTimeOut } from "Components/Utils/SessionTimeOut";
import ReactDOM from 'react-dom/client';
import { toast } from "react-toastify";

const getBaseUrl = (module) => {
    if (module.includes("/workflow") || module.includes("/tickets") || module.includes("/notification")) {
        return `${window.env.REACT_APP_BASE_URL}`
    }
    else {
        return `${window.env.CAMUNDA_BASE_URL}`
    }
}

const api = axios.create()

api.interceptors.request.use(
    (config) => {
        const accessToken = sessionStorage.getItem('authToken');
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`

            if (config.url.includes("/status")){
                config.headers["Cache-Control"] = "no-cache"
                config.headers.Accept = "text/event-stream"
            } else{
                config.headers["Content-Type"] = "application/json"
            }

            config.validateStatus = function(status) {
                return status >= 200 && status <= 504
            }

            // Set a timeout of 60 seconds (60000 milliseconds)
            config.timeout = 60000
        }
        
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

api.interceptors.response.use(
    async (response) => {
        if (response.status === 401) {
            sessionStorage.clear()
            const root = ReactDOM.createRoot(document.getElementById('processingContent'));
            root.render(<SessionTimeOut />);
        } 
        return response.data;
    },
    async (error) => {
        if (error.code === 'ECONNABORTED') {
            toast.error(
                `Sorry, the request took too long to process. Please try again later.`, { position:"top-center" }
            )            
            console.error('Request timed out:', error.config);
        }
        if (error.response.status === 401) {
            const root = ReactDOM.createRoot(document.getElementById('processingContent'));
            sessionStorage.clear()
            root.render(<SessionTimeOut />);
        }
        else if (error.response && error.response.status >= 500) {
            // toast.error("An Error occurred. Please try again later.");
            toast.error(
                `Oops! Something went wrong on our end. Please try again later.`, { position:"top-center" }
            )  
            console.error("Interal server error")
        }
        return Promise.reject(error)
    }
)

export const determineBaseUrlAndPerformApiCall = async (endpoint, method, data) => {
    api.defaults.baseURL = getBaseUrl(endpoint)
    const response = await api.request({
        method: method,
        url: endpoint,
        data: data
    })
    return response
}

export default api