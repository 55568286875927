import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './slices';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import NetworkStatusPopup from 'slices/NetworkContext/NetworkPopup';
import { NetworkStatusProvider } from 'slices/NetworkContext/NetworkStatusContext';

const store = configureStore({ reducer: rootReducer, devTools: true });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const clientId = window.env.REACT_APP_CLIENT_ID
// const appUrl = window.env.REACT_APP_UI_URL
const authority = window.env.REACT_APP_AUTHORITY


const configuration: any = {
  auth: {
    clientId: clientId,
    redirectUri: '/blank.html',
    authority: authority
  }
};

const pca = new PublicClientApplication(configuration);

root.render(
  <NetworkStatusProvider>
    <NetworkStatusPopup />
    <MsalProvider instance={pca}>
      <Provider store={store}>
        <React.StrictMode>
          <BrowserRouter basename={window.env.PUBLIC_URL}>
            <App />
          </BrowserRouter>
        </React.StrictMode>
      </Provider>
    </MsalProvider>
  </NetworkStatusProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
